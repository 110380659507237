.publish-survey-dialog-body {
	margin: 0 48px 0 32px;

	&__section-title {
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 24px;
	}
}

@media only screen and (max-width: 599px) {
	.publish-survey-dialog-footer {
		padding: 0 48px 0 32px;
	}
}