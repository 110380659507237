$primary-font: 'CeraPro';
$font-weight-regular: 400;
$font-weight-bold: 700;

.underline {
	text-decoration: underline;
}

.italic {
	font-style: italic;
}

.bold {
	font-weight: $font-weight-regular;
}
