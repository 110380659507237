// Reference: https://css-tricks.com/snippets/css/custom-checkboxes-and-radio-buttons/
// and: http://www.456bereastreet.com/archive/201211/accessible_custom_checkboxes_and_radio_buttons/

.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    color: $light-gray-two;
  }

  // Box
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    background: white;
    border: 1px solid $light-gray-two;
    box-sizing: border-box;
  }

  // Disabled state label
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    width: 9px;
    height: 9px;
    background: url('../images/icon_check.png') no-repeat center;
    background-size: contain;
  }
}

.checkbox {
  .md-btn--icon {
    width: 45px;
    height: 45px;

    &.md-text--theme-secondary {
      /*color: $green;*/
    }
  }

  .material-icons {
    &.md-icon {
      font-size: 25px;
    }
  }
}

// REACT-MD CHECKBOX CLASS
.md-selection-control-container {
  &.checkbox--height-20 {
    height: 20px;
  }
}