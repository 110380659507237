@font-face {
  font-family: 'CeraPro';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("CeraPro-Regular.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'CeraPro';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("CeraPro-Italic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'CeraPro';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("CeraPro-Italic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'CeraPro';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("CeraPro-Medium.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'CeraPro';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("CeraPro-Bold.woff?v=3.13") format("woff");
}

h1, h2, h3, h4, h5, h6, p, button, input:not([type="password"]), textarea, html {
  font-family: CeraPro, "Roboto", sans-serif !important;
}