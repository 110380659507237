.message-list-page {
  &__header {
    height: 274px;
  }

  &__add-and-search {
    display: flex;
    justify-content: space-between;
    padding: 40px;
  }

  &__create-message-button {
    width: 214px;
  }
}

.wysiwyg {
  border-radius: 4px;

  &__toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-bottom: 0;
    border: 1px solid $light-gray-eight;
    border-bottom: 0;
  }

  &__editor {
    border: 1px solid $light-gray-eight;
    padding: 0 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    min-height: 240px;
    max-height: 240px;
  }
}

.send-message-content {
  padding: 0 30px;
}

.send-message {
  &__section-title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }

  &__contact-method-checkboxes {
    display: flex;
    justify-content: space-between;
  }
}

.view-message {
  &__message-section {
    margin-bottom: 24px;
  }

  &__sent-to-section {
    margin-bottom: 40px;
  }

  &__section {
    box-sizing: border-box;
    border: 1px solid $light-gray-twelve;
    border-radius: 4px;
    background-color: $white;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 16px 33px;
  }

  &__heading {
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0;
  }

  &__action-buttons {
    display: flex;
    justify-content: flex-end;

    &--inbox {
      padding: 56px 34px 40px 0;
    }
  }

  &__button {
    width: 195px;

    &--back {
      margin-right: 16px;
    }
  }

  &__send-message {
    width: 60%;
  }
}

.message-details {
  padding: 42px 32px;
  box-sizing: border-box;
  min-height: 400px;
  margin-bottom: 24px;

  &__details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  &__details-section {
    display: flex;
    flex-direction: column;
  }

  &__subject {
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0;
  }

  &__sender {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }

  &__date {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;

    &--expiry {
      color: $light-gray-fifteen;
    }
  }

  &__content {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
  }
}

.delete-message-footer {
  margin-right: 0;
  justify-content: center;
}

.sending-method {
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  height: 24px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-transform: capitalize;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  &--all {
    background-color: rgba(126,136,142,0.1);
  }

  &--internal {
    background-color: rgba(149,53,143,0.1);
  }

  &--sms {
    background-color: rgba(4,128,26,0.1);
    text-transform: uppercase;
  }

  &--email {
    background-color: rgba(0,48,116,0.1);
  }
}

.inbox-list {
  .add-and-search {
    justify-content: flex-end;
  }

  &__body {
    margin: 40px;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    margin: auto;
    margin-right: 0;
    margin-bottom: 16px;
    width: 194px;
  }
}

.inbox-table {
  margin: 0;

  &__row {
    &--unread {
      font-weight: bold;
    }
  }
}

.move-to-inbox-dialog, .delete-message-dialog {
  width: 610px;
}

.move-to-inbox-footer {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 599px) {
  .send-message {
    &__contact-method-checkboxes {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .view-message {
    &__send-message {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 599px) {
  .inbox-list {
    .add-and-search {
      padding-top: 16px;
      padding-bottom: 30px;
    }
  }

  .view-message {
    &__header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__body {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    margin: 31px 15px;

    &__header {
      padding: 8px 16px;
    }

    &__heading {
      font-size: 16px;
    }

    &__action-buttons {
      flex-direction: column-reverse;
      padding: 48px 16px 32px;
    }

    &__button {
      width: 100%;

      &--back {
        margin-top: 24px;
        margin-right: 0;
      }
    }
  }

  .message-details {
    &__subject {
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 27px;
    }

    &__sender {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
    }

    &__date {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }

  .move-to-inbox-dialog {
    min-width: 95%;
    max-width: 95%;
  }

  .delete-message-dialog {
    min-width: 95%;
    max-width: 95%;
  }

  .delete-message-body {
    height: 300px;
    overflow: auto;
  }

  .delete-message-footer {
    flex-direction: column-reverse;
    margin: 0;
    padding: 24px 16px 32px;
  }

  .move-to-inbox-body {
    padding-top: 16px;
  }

  .move-to-inbox-footer {
    width: 100%;
    margin: 0;
    padding: 24px 16px 32px;

    &__button {
      width: 100%;
    }
  }

  .inbox-list {
    &__body {
      margin: 16px;
    }

    &__button {
      margin-bottom: 8px;
      width: 40px;
      height: 40px;
    }
  }
}