.resources {
    &__dialog {
        &--container {
            .resource-option {
                width: 100%;
                margin-bottom: 20px;
                /*border: 1px solid;*/
            }

            .resource-details,
            .resource-schedule {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .resource-name {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                width: 40%;
            }

            .resource-status {
                width: 60%;
            }

            .resource-schedule {
                margin: 20px 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 5px;
            }

            .resource-schedule-check {
                .form__not-applicable-checkbox {
                    margin-top: unset;
                }
            }

            .resource-schedule-date {
                margin-top: 15px;
            }

            .resource-schedule-date-label {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                color: #353535;
                margin-bottom: 10px;
            }

            .resource-schedule-calendar {
                display: flex;
                align-items: center;
                justify-content: space-between;

                & >:nth-child(1),
                & >:nth-child(3) {
                    width: 45%;
                }

                .datepicker
                .datepicker__icon {
                    top: 10px;
                }

                span {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                    color: #353535;
                }
            }
        }
    }
}

/*
    <div></div>

*/