/* BUTTONS */
.spinner {
  border: 5px solid $light-gray-eight;
  border-top: 5px solid $light-gray-nine;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.action-button {
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;

  img {
    height: 20px;
    vertical-align: middle;
    margin-right: 7px;
  }

  &--remove {
    color:$red-one;
  }

  &--add {
    color: $blue-one;
  }
}