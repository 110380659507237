.schedule-header {
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
}

.date-controls {
  display: flex;

  .date-control {
    @include themify($themes) {
      background: themed('secondary');
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    cursor: pointer;

    &.prev {
      margin-right: 16px;
    }

    &.next {
      margin-left: 16px;
    }
  }

  .current-date {
    @include themify($themes) {
      color: themed('secondary');
    }
    font-size: 16px;
    text-align: center;
    width: 210px;
  }
}

.attendance-switch {
  display: flex;
  align-items: center;

  .md-switch-track--on {
    background: $green-two;
  }
  .md-switch-thumb--on {
    background: $white;
  }
}

// SELECT REWARD DIALOG
.select-reward-dialog {
  width: 800px;

  footer {
    display: none;
  }
}

.reward-points-dialog {
  section {
    @extend %section-background;
    @extend %scrollbar-design;
    position: unset;
    padding: 0;
  }
}

.reward-points-dialog-body {
  @extend %scrollbar-design;
  max-height: 500px;

  &__search {
    position: absolute;
    top: 64px;
    right: 40px;
  }
}

.view-session-details-dialog {
  width: 508px;

  footer {
    display: none;
  }
}

.session-details {
  margin-bottom: 30px;

  &__label {
    display: block;
    height: 18px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-bottom: 9px;
  }

  &__value {
    height: 19px;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }
}

@media only screen and (max-width: 599px) {
  .event-items {
    width: 85%;
  }

  .event-item {
    height: 64px;
    padding: 8px;
    overflow: hidden;

    &__p {
      font-size: 16px;
      line-height: 24px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .event-date {
    margin-right: 0;
    font-size: 16px;
    width: 40px;

    &--current {
      .event-date {
        &--date {
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }

  .schedule {
    &__legend-list {
      margin: 0 20px;
      justify-content: space-between;
      padding-bottom: 16px;
    }

    &__legend-list-item {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  .session-details-dialog {
    min-width: 90%;
    min-height: 95%;
    border-radius: 4px;
  }

  .session-details-dialog-body {
    height: 64vh;
  }
}