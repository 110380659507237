.action-column {
  width: 80px;
}

.page-table {
  .pagination {
    margin: 40px auto;
    display: block;
  }
}

.table-icon {
  height: 24px;
}

.table-empty-data {
  text-align: center;
  font-size: 16px;
  padding: 20px;
}

.column-no-action {
  display: inline-block;
  width: 40px;
}

@media only screen and (max-width: 962px) {
  .launchpad-table {
    .md-table-column--header {
      font-size: 14px;
    }

    thead {
      &.md-table-header {
        .md-table-row {
          th {
            .md-icon-text {
              &:first-child {
                padding-right: 4px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .launchpad-table {
    .md-data-table {
      table-layout: fixed;
    }

    .md-table-row {
      height: 35px;
    }

    .md-table-checkbox {
      .md-selection-control-container {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .md-table-column {
      font-size: 12px;

      &--adjusted {
        padding-right: 8px;
      }

      &:first-child {
        padding-left: 8px;
      }

      &--header {
        height: 35px;
      }
    }
  }

  .action-column {
    width: 60px;
  }

  .page-table {
    .pagination {
      margin: 32px auto;
    }
  }
}
