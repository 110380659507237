%form-field-label {
  height: 18px;
  font-size: 12px;
  color: $black-one;
  line-height: 18px;
  font-weight: bold;
  display: block;
}

// For messages like errors
.md-text-field-message-container {
  .md-text-field-message {
    height: 18px;
    line-height: 18px;
    letter-spacing: 0.1px;
  }
}

 // FORM FIELD
 .form-field {
  height: 76px;
  position: relative;

   span {
     @extend %form-field-label;
   }

   input {
    width: 100%;
    height: 40px;
    border: 1px solid $light-gray-eight;
    border-radius: 4px;
    padding: 0 8px;
   }

   &.width-45 {
     width: 45%;
   }

   .md-text-field-message-container {
     bottom: 0;
   }
 }