.no-points {
  padding: 40px;
  text-align: center;

  &__img {
    margin-bottom: 48px;
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 16px;
    text-align: center;
  }

  &__description {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    max-width: 567px;
    margin: 0 auto;
  }
}

.create-points {
  &__title {
    display: block;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
  }

  &__sub-title {
    font-size: 16px;
    line-height: 24px;
    min-height: 50px;
  }

  &__save {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__add-new-type {
    height: 24px;
    margin-bottom: 40px;
  }

  &__add-new-type-text-block {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    width: fit-content;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }
  }

  &__add-new-type-icon {
    width: 19px;
    margin-right: 8px;
  }

  &__add-new-type-text {
    color: $blue-one;
    font-weight: bold;
  }

  &__save-button {
    width: 188px;
  }
}

.point-form {
  &__type-of-points {
    color: $blue-one;
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__block {
    display: flex;
  }

  &__field {
    width: 30%;
    margin-bottom: 16px;
    position: relative;
    display: inline-block;

    &:not(:last-child) {
      margin-right: 5%;
    }
  }

  &__multiline-text-field {
    margin-bottom: 24px;
  }

  &__not-applicable-checkbox {
    position: absolute;
    top: 60px;
  }
}

.points-card {
  display: flex;
  align-items: center;
  padding: 40px 48px 40px 40px;

  &:nth-child(even) {
    background: rgba(198, 203, 212, 0.24);
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__name {
    display: block;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.54px;
    margin-bottom: 13px;
  }

  &__description {
    font-size: 18px;
    letter-spacing: 0.45px;
    line-height: 24px;
    margin-bottom: 0;
  }

  &__item-text-block {
    width: 80%;
    margin-right: 80px;
  }

  &__item-points-block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 65px;
    min-width: 65px;
    border: 1px solid $black-one;
    border-radius: 4px;
    margin-right: 80px;
  }

  &__item-points {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.59px;
    line-height: 28px;
  }

  &__icons {
    height: 24px;
    display: flex;
  }

  &__icon-container {
    height: 24px;

    &:hover {
      cursor: pointer;
    }

    &--edit {
      margin-right: 40px;
    }
  }

  &__icon {
    max-height: 100%;
  }
}

.with-center-line {
  position: relative;

  .center-element {
    position: absolute;
    padding: 0 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .center-line {
    position: absolute;
    width: 100%;
    top: 50%;
    z-index: 1;
    border-bottom: 1px solid rgba(151, 151, 151, 0.21);
  }
}

.new-survey-dialog-body {
  padding: 10px 30px;
}

@media only screen and (max-width: 962px) {
 .points-card {
   &__item-text-block {
    margin-right: 40px;
   }

   &__item-points-block {
    margin-right: 40px;
  }
 }
}

@media only screen and (min-width: 963px) and (max-width: 1024px) {
  .points-card {
    &__item-text-block {
      margin-right: 50px;
    }

    &__item-points-block {
      margin-right: 50px;
    }
  }
}