

/*waiver page, create dialog and edit dialog*/
.waiver-preview {
    border: 1px solid #cccccc;
    padding: 4px 8px;
    margin: 0;
    min-height: 310px;
    /*max-height: 310px;*/
    /*overflow-x: auto;*/

    p {
        padding: 0;
        margin: 0;
    }
}