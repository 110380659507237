html,
body {
  background: #fff !important;
  font-size: 16px !important;
  // height: 100%;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}
body {
  font-family: $primary-font, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $white;
  height: 100%;
  // WHEN DIALOG OPENS
  &.md-no-scroll {
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #919191;
    border-radius: 50px;
  }
}

.block {
  display: block;
}

.expanded {
  width: 100%;
}

.required {
  color: $red-one;
}

.wrapper {
  display: block;
  overflow: auto;
  margin-left: 320px;
  height: 100vh;
  transition: margin-left 0.5s;
  position: relative;
}

.add-and-search {
  @extend %top-section;
  display: flex;
  justify-content: space-between;

  button {
    height: 40px;
    width: 251px;
    letter-spacing: 0.27px;
  }
}

// SCROLLBAR
%scrollbar-design {
  @include themify($themes) {
    scrollbar-color: themed('scrollbar-color');
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: $scrollbar-color;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    @include themify($themes) {
      background-color: themed('primary');
    }
    border-radius: 4px;
  }
}

@media only screen and (max-width: 962px) {
  .page {
    .add-and-search {
      * {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .wrapper {
    height: calc(100vh - 24px);
  }
}

.drawer-default-overlay {
  background: #172271;
  opacity: 0.25;
}

.md-drawer {
  box-shadow: none;
}
