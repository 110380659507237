.md-dialog--centered {
  transform: translate(-50%, -50%);
}

.md-dialog-container.md-overlay {
  z-index: 1900001;

  &:has(.map-dialog-container) {
    z-index: 1900010;
  }
}

.minimize-dialogs {
  height: 40px;
  width: auto;
  right: 0;
  background: transparent;
  z-index: 19;
  position: absolute;
  bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    min-width: 250px;
    margin-left: 20px;
    padding: 0 24px;

    svg {
      color: #fff;
      margin-left: 10px;
    }

    &:last-child {
      margin-right: 50px;
    }
  }
}

.i_dialog {
  &_container {
    min-height: 146px;
    width: 1008px;
    overflow-y: auto;
  }

  &_body {
    padding: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .i_dialog {
    &_container {
      min-width: 1008px;

      &.add-session-dialog {
        min-width: unset;
      }

      &.add-new-program-dialog {
        min-width: unset;
      }

      &.no-session-dialog {
        min-width: unset;
      }
    }
  }
}

.confirm-delete-event-dialog {
  section {
    margin: 40px 87px 0;
    font-size: 24px;
  }
}

// widths
// adding points: 487px
// adding program: 720px
// registering participant: 906px
// creating new participant: 906px
// sign up to program: 1008px
// creating type of skill: 487px

.participant-dialog {
  &__header {
    background-repeat: no-repeat;
    padding: 0;
    height: 24px;

    .i_dialog_title-title {
      display: none;
    }

    .i_dialog_title-close {
      display: none;
    }
  }

  &__footer {
    height: 24px;
  }
}

.export-dialog {
  section.md-dialog-content {
    min-height: 350px;
  }
}

#map-dialog_dialog {
  .map-dialog-container {
    position: relative;
  }
  section.md-dialog-content {
    width: 100%;
  }
}
