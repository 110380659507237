%base {
	box-sizing: border-box;
	height: 40px;
	width: 336px;
	border: 1px solid #BEC1C5;
	border-radius: 10px;
	background-color: #FFFFFF;
	box-shadow: 0 0 3px 0 rgba(0,0,0,0.05);
}

.login {
  min-height: 840px;
  height: 100vh;
  display: flex;

  &__form {
    width: 336px;
  }

  &__title {
    color: #003074;
    font-family: $primary-font, sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.4px;
    margin: 0 0 24px 0;
  }

  &__section {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;

    &--login-form {
      height: 100%;
      background: $white;
    }

    &--logo {
      background: url('../images/login_background.png') no-repeat center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }

  &__launchpad-logo {
    width: 285px;
    height: 252px;
  }

  &__launchpad-logo-img {
    max-width: 100%;
  }

  &__misc {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    font-size: 16px;
  }

  &__remember-me {
    color: $light-gray-two;
  }

  &__forgot-password {
    a {
      color: $blue-one;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.md-password-btn.md-password-btn {
  margin-right: 13px;
}

// WAIVER
.waiver-dialog {
  width: 888px;
}

.waiver-dialog-body {
  &__logo {
    height: 73px;
    margin: 16px 0 28px 16px;
  }

  &__content {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 30px 30px 0;
  }

  &__image {
    height: 102px;
    margin: 0 auto;
    display: block;
  }

  &__title {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0.67px;
    line-height: 48px;
    margin: 16px 0 24px;
  }

  &__text {
    font-size: 32px;
    letter-spacing: 0.67px;
    line-height: 48px;
  }

  &__action-buttons {
    width: 100%;
    margin: 48px auto 109px;
    display: flex;
    justify-content: center;
  }

  &__button {
    width: 208px;

    &:not(:last-child) {
      margin-right: 32px;
    }
  }
}

.waiver-text {
  p {
    text-align: justify;
  }

  ol {
    counter-reset: item;

    &.no-counter-reset {
      counter-reset: none;
    }

    li {
      display: block;
      position: relative;

      &:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        position: absolute;
        margin-right: 100%;
        right: 20px;
      }
    }

    //sub-list of sub-list
    ol {
      li {
        &:before {
          content: counters(item, ".") " ";
        }
      }
      ol {
        counter-reset: list;

        li {
          list-style: none;
          position: relative;

          &:before {
            counter-increment: list;
            content: "(" counter(list, lower-alpha) ") ";
            position: absolute;
            right: 20px;
          }
        }
      }
    }
  }
}

.reset-password-dialog {
  width: 887px;
}

.reset-password-dialog-body, .reset-password-page {
  padding: 0 40px;

  &__content {
    width: 492px;
    margin: 0 auto;
  }

  &__header {
    text-align: center;
    margin-top: 64px;
  }

  &__logo {
    height: 95px;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0.67px;
    line-height: 48px;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 16px;
    letter-spacing: 0.33px;
    line-height: 24px;
  }

  &__text-field {
    margin-bottom: 24px;

    .md-text-field {
      width: 100%;
    }
  }

  &__condition-message {
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 28px;
    margin: 0;
  }

  &__conditions {
    font-size: 12px;
    margin-top: 8px;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__condition {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
  }

  &__button {
    width: 208px;
  }
}

.reset-password-check {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  margin: 0 10px;

  .md-icon {
    &.material-icons {
      font-size: 15px;
      color: $white;
    }
  }

  &--false {
    background: $light-gray-four;
  }

  &--true {
    background: $participant-green;
  }
}

.reset-password-page {
  padding: 0;

  &__top {
    height: 24px;
  }

  &__footer {
    height: 24px;
  }
}

@media screen and (min-width: 1025px) {
  .reset-password-check {
    .md-icon {
      &.material-icons {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (device-width: 1024px) {
  .waiver {
    &__body {
      height: unset;
    }
  }
}

@media only screen and (device-width: 768px) {
  .waiver {
    &__body {
      height: calc(100vh - 240px);
    }
  }
}

@media screen and (max-width: 1024px) {
  .waiver {
    &__details {
      height: calc(100% - 240px);
    }

    &__checkbox {
      margin-bottom: 10px;

      .md-btn {
        &--icon {
          width: 40px;
          height: 40px;
          padding: 8px;
        }
      }

      .md-selection-control-label {
        font-size: 13px;
      }
    }

    &__signature {
      width: 50%;
    }

    &__submit-button {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}

@media screen and (max-width: 767px) {
  .waiver {
    &__section-title {
      margin: 10px 0;
      font-size: 18px;
    }

    &__details {
      padding: 10px;
    }

    &__text {
      font-size: 24px;
    }
  }

  .waiver-text {
    h3 {
      font-size: 17px;
    }

    ol {
      padding-inline-start: 30px;
    }
  }

  .waiver-dialog-body {
    &__logo {
      height: 60px;
    }

    &__image {
      height: 62px;
    }

    &__title {
      margin: 16px 0;
    }

    &__text {
      font-size: 20px;
      line-height: 32px;
      padding: 10px;
    }

    &__button {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    &__action-buttons {
      flex-direction: column;
      align-items: center;
      margin: 32px auto;
    }
  }
}

@media screen and (max-width: 714px) {
  .login {
    background: url('../images/login_background.png') no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__title {
      color: $white;
      font-size: 22px;
      text-align: center;
    }

    &__launchpad-logo-form {
      display: block;
      width: 30%;
      margin-bottom: 16px;
    }

    &__section {
      &--login-form {
        height: fit-content;
        border-radius: 4px;
        width: 100%;
        padding: 24px;
        background: transparent;
      }

      &--logo {
        display: none;
      }
    }

    &__text-field {
      .input-with-icon {
        &__icon {
          display: none;
        }

        &__label {
          color: $white;
          font-size: 16px;
          margin-bottom: 7px;
        }

        &__field {
          background: $white;
          border-radius: 9px;

          .md-text-field-container {
            padding: 0 16px;

            input {
              width: calc(100% - 32px);

              &[type=password] {
                width: calc(100% - 56px);
              }
            }
          }
        }
      }
    }

    &__remember-me {
      visibility: hidden;

      label {
        color: $white;

        &:before {
          background: transparent;
          border: 1px solid $white;
        }
      }
    }

    &__forgot-password {
      a {
        color: $white;
      }
    }

    &__login-button {
      color: $blue-one;
      background: $white;

      &:hover {
        color: $blue-one;
        background: $white;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .waiver {
    min-width: 100%;
    min-height: 100%;
    border-radius: 0;

    &__logo {
      margin: 0 auto;
    }

    &__title {
      display: none;
    }

    &__section-title {
      font-size: 16px;
    }

    &__details {
      border: 0;
    }
  }

  .waiver-text {
    h3 {
      font-size: 15px;
    }

    h4 {
      font-size: 14px;
    }
  }

  .waiver-dialog {
    min-height: 100%;
    min-width: 100%;
    border-radius: 0;
  }

  .waiver-dialog-body {
    &__logo {
      display: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__image {
      order: 3;
      height: 177px;
    }

    &__title {
      order: 1;
      font-size: 22px;
    }

    &__text {
      order: 2;
      font-size: 16px;
      line-height: 24px;
    }

    &__action-buttons {
      order: 4;
      flex-direction: column-reverse;
    }

    &__button {
      width: 80%;

      &:not(:last-child) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 16px;
      }
    }
  }

  .participant-dialog {
    position: relative;
    min-width: 100%;
    min-height: 100%;
    border-radius: 0;

    &__header {
      background: unset;
    }

    &__body {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &__footer {
      background: unset;
    }
  }

  .page-reset-password {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .reset-password-dialog {
    min-width: 100%;
    min-height: 100%;
    border-radius: 0;
  }

  .reset-password-dialog-body {
    &__header {
      margin-top: 8px;
    }

    &__content {
      width: 100%;
    }

    &__title {
      font-size: 22px;
      line-height: 33px;
    }

    &__text-field {
      margin-bottom: 16px;
    }

    &__action-buttons {
      flex-direction: column-reverse;
    }

    &__button {
      width: 100%;

      &--next {
        margin-bottom: 16px;
      }
    }
  }

  .reset-password-page {
    position: relative;
    &__top {
      display: none;
    }

    &__header {
      margin-top: 0;
    }

    &__content {
      width: 90%;
    }

    &__action-buttons {
      flex-direction: column-reverse;
    }

    &__button {
      width: 100%;

      &--next {
        margin-bottom: 16px;
      }
    }

    &__footer {
      display: none;
    }
  }
}

@media only screen and (max-height: 767px) {
  .waiver {
    &__details {
      height: calc(100% - 100px);
    }
  }
}