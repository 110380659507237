/* COLORS */
$black: #000000;
$black-one: #212121;
$white: #FFFFFF;
$white-two: #E6F2FD;
$blue-one: #003074;
$blue-three-light: rgba(0, 62, 126, 0.16);
$blue-four: #4B74FF;
$blue-five: #1966B5;
$light-blue: #D7E3F1;
$light-blue-two: #3A7AB0;
$light-gray-one: #A0A0A0;
$light-gray-two: #586166;
$light-gray-three: #BEC1C5;
$light-gray-four: #979797;
$light-gray-five: #E6E8ED;
$light-gray-six: #E6E6E6;
$light-gray-seven: #DBDBDB;
$light-gray-eight: #CCCCCC;
$light-gray-nine: #7E888E;
$light-gray-ten: #BDBDBD;
$light-gray-eleven: #D8D8D8;
$light-gray-twelve: #EAEAEA;
$light-gray-thirteen: #777777;
$light-gray-fourteen: #ECECEC;
$light-gray-fifteen: #9C9C9C;
$light-gray-sixteen: #7B7B7B;
$dark-gray: #272727;
$dark-gray-two: #333333;
$dark-gray-three: #353535;
$dark-gray-four: #51516B;
$dark-gray-two-light: rgba(0, 0, 0, 0.64);
$magenta: #95358F;
$red-one: #AE132A;
$green: #04801A;
$green-two:#57B367;
$green-light:rgba(4, 128, 26, 0.1);

$primary-font: 'CeraPro';

$wrapper-gap: 32px;

$header-height: 80px;

$md-secondary-color: $blue-one; // used for tab indicators
$scrollbar-color: rgba(189, 189, 189, 0.34);

$table-alternate-color: rgba(223, 226, 230, 0.24);
$table-hover-color: rgba(75, 116, 255, 0.1);
$detail-card-value-bg-one: rgba(80, 143, 197, 0.07);
$detail-card-value-bg-two: rgba(0, 134, 103, 0.07);
$calendar-border-color: #D7E3F1;

$participant-green: #008667;

$schedule-table-header-color: #F3F3F3;
$disabled-text-color: #545454;
$disabled-field-bg-color: #F2F2F2;

$themes: (
  admin: (
    primary: $magenta,
    secondary: $blue-one,
    profile-pic-border: 1px solid $white,
    participant-pic-border: 1px solid $magenta,
    pagination-prev-icon: url('../images/icon_pagination_prev_magenta.png'),
    pagination-next-icon: url('../images/icon_pagination_next_magenta.png'),
    scrollbar-color: $magenta $scrollbar-color,
    dialog-title-color: $blue-one,
    detail-card-label-bg: $blue-one,
    detail-card-value-bg: $detail-card-value-bg-one
  ),
  coach: (
    primary: $blue-one,
    secondary: $blue-one,
    profile-pic-border: 1px solid $blue-one,
    participant-pic-border: 1px solid $blue-one,
    pagination-prev-icon: url('../images/icon_pagination_prev_blue.png'),
    pagination-next-icon: url('../images/icon_pagination_next_blue.png'),
    scrollbar-color: $blue-one $scrollbar-color,
    dialog-title-color: $blue-one,
    detail-card-label-bg: $blue-one,
    detail-card-value-bg: $detail-card-value-bg-one
  ),
  participant: (
    primary: $participant-green,
    secondary: $participant-green,
    profile-pic-border: 1px solid $black,
    participant-pic-border: 1px solid $black,
    pagination-prev-icon: url('../images/icon_pagination_prev_blue.png'),
    pagination-next-icon: url('../images/icon_pagination_next_blue.png'),
    scrollbar-color: $participant-green $scrollbar-color,
    dialog-title-color: $black,
    detail-card-label-bg: $participant-green,
    detail-card-value-bg: $detail-card-value-bg-two
  )
);

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {

    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }

  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}