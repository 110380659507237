%top-section {
  box-sizing: border-box;
  background: $white;
  // box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);
}

%section-background {
  margin: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
  padding: 40px 48px 40px 40px;
  position: relative;
}

.search-field {
  height: 40px;
  width: 321px;
}

.coach-edit-dialog {
    .md-dialog {
        overflow-x: hidden;
        width: 424px;

        .i_dialog_title,
        .i_dialog_body,
        .i_dialog_footer {
            margin-left: 40px;
            margin-right: 40px;
        }

        .i_dialog_title {
            margin-top: 40px;
            button {
                right: -25px;
            }
        }

        .i_dialog_footer {
            padding-left: 0;
            padding-right: 0;
        }

        .i_dialog_body > div {
            padding: 30px 0;
            min-height: 250px;
        }
    }
}

.coaches {
  &__page {
    height: 100%;
  }

  &__form {
    @extend %section-background;
  }
}
.coaches__form {
  .form {
    &__section {
      width: 100%;
      display: flex;
      flex-direction: column;

      &--full {
        width: 100%;
      }
    }

    &__section-address-group {
      .form-section-details {
        .form-field {
          width: 45%;
          margin-bottom: 24px;
        }

        .text-field {
          margin-bottom: 24px;
        }
      }
    }

    &__block {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 24px;

      > div {
        width: auto;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--block {
        display: block;
      }
    }

    &__school-field {
      margin-bottom: 0;
    }

    &__not-applicable-checkbox {
      margin-top: 8px;
      height: 24px;

      .md-selection-control-label {
        font-size: 14px;
      }

      .md-btn--icon {
        height: 24px;
        padding: 0;
        width: 32px;
      }
    }
  }
}

.coach-action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 56px;

  button {
    width: 208px;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.coach-detail {
  @extend %section-background;

  .md-grid {
    padding: 0;

    .md-cell {
      margin: 12px 0;
    }
  }

  &__edit-information {
    position: absolute;
    color: $blue-one;
    display: flex;
    align-items: center;
    font-size: 16px;
    letter-spacing: 0.27px;
    line-height: 24px;
    height: 24px;
    top: 24px;
    right: 30px;
    cursor: pointer;

    img {
      width: 18px;
      margin-right: 8px;
    }
  }
}

.coach-overview {
  @extend %top-section;
  display: flex;
  padding: 48px 44px;

  &__upload-photo {
    position: relative;
    overflow: hidden;
    width: 174px;
    height: 174px;
    border-radius: 4px;
    margin-right: 64px;
    cursor: pointer;
  }

  &__upload-photo-img {
    position: absolute;
    max-width: 100%;
  }

  &__upload-photo-text {
    @include themify($themes) {
      background: themed('secondary');
    }
    display: inline-block;
    width: 100%;
    height: 34px;
    color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 12px;
    font-weight: bold;
    line-height: 34px;
    text-align: center;
  }

  &__name {
    font-size: 32px;
    font-weight: bold;
    line-height: 38px;
    margin-bottom: 15px;
  }

  &__member-id {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  &__member-id-text-field {
    height: 19px;
    width: 150px;
    margin: 0 5px;
    position: relative;

    .md-text-field-container {
      height: 19px;

      .md-text-field {
        height: 19px;
        font-size: 14px;
      }

      .md-text-field-message-container {
        position: absolute;
        left: 160px;
      }
    }
  }

  &__member-id-edit-icon {
    height: 16px;
    margin-left: 8px;
    cursor: pointer;
  }

  &__username {
    color: $black-one;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 4px;
    width: fit-content;
  }

  &__link {
    color: $blue-one;
    display: block;
    line-height: 19px;
    text-decoration: underline;
    width: fit-content;
    cursor: pointer;

    &--reset-password {
      margin-bottom: 4px;
    }

    &--view-waiver {
      margin-bottom: 23px;
    }
  }

  &__points-block {
    display: flex;
  }

  &__reward-points {
    display: flex;
  }

  &__points-button {
    @include themify($themes) {
      background: themed('secondary');
    }
    height: 32px;
    width: 130px;
    margin-right: 5px;
  }

  &__edit-points-button {
    height: 32px;
    width: 144px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue-one;
    box-shadow: unset;
  }

  &__edit-points-icon {
    height: 16px;
    margin-right: 8px;
  }

  &__edit-points-text {
    &.md-icon-text {
      font-weight: 400;
      font-size: 14px;
    }
  }

  &__rewards {
    height: 32px;
    margin-right: 24px;
  }

  &__rewards-icon {
    width: 27px;
    margin-right: 14px;
    margin-top: 5px;
  }

  &__number-of-points {
    font-size: 32px;
    line-height: 32px;
    margin-right: 7px;
  }

  &__points {
    font-size: 16px;
  }
}

.form-section-title {
  height: 28px;
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 24px;

  &--margin-top-0 {
    margin-top: 0;
  }

  &--margin-top-8 {
    margin-top: 8px;
  }

  &__icon {
    height: 28px;
    margin-right: 16px;
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
    @include themify($themes) {
      color: themed('secondary');
    }
  }
}

.coach-info {
  color: $black-one;
  height: 50px;

  span {
    display: block;

    &.label {
      min-height: 16px;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      margin-bottom: 6px;
    }

    &.value {
      font-size: 16px;
      line-height: 19px;
      min-height: 21px;
      overflow-wrap: break-word;
    }
  }

  &.list {
    padding: 0;
    margin: 0;

    div {
      padding: 0;
      margin: 0;

      &.labels {
        .md-cell {
          margin: 0;
        }
      }

      &.values {
        .md-cell {
          line-height: 19px;
          margin-top: 0;
          margin-bottom: 7px;
        }
      }
    }
  }
}

.contact-method {
  margin: 0;
  padding: 0;

  .contact-method-label {
    height: 19px;
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 16px;
  }

  // first contact-method radio
  > .contact-method-radio {
    margin-bottom: 8px;
  }

  .contact-method-radio {
    height: 16px;

    &:first-child {
      margin-bottom: 8px;
    }

    .md-selection-control-label {
      font-size: 14px;
      height: 16px;
    }

    .md-selection-control-toggle {
      padding: 0;
      height: 16px;
      width: 16px;
      margin-right: 7px;

      .md-icon {
        font-size: 16px;
      }
    }
  }
}

.note-card {
  &:not(:first-child) {
    margin-top: 24px;
  }

  &__title {
    @include themify($themes) {
      color: themed('primary');
    }
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
  }

  &__subtitle {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  &__body {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #D0D0D0;
  }
}

.members-list {
  @extend %scrollbar-design;
  overflow: auto;
  height: 370px;
  margin-top: 22px;

  &__title {
    font-size: 16px;
    letter-spacing: 0.13px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 33px 18px 8px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(151, 151, 151, 0.37);
    }
  }
}

.check-in-members-dialog {
  height: 635px;
  width: 613px;
}

.check-in-members-dialog-body {
  overflow: hidden;

  .checkin-switch {
    display: flex;
      align-items: center;

      .md-switch-track--on {
        background: $green-two;
      }
      .md-switch-thumb--on {
        background: $white;
      }

      label {
            flex-direction: row-reverse;
            display: flex !important;

            height: 18px;
            font-size: 12px;
            color: #212121;
            line-height: 18px;
            font-weight: bold;
            display: block;
      }
  }

  &-member-id {
        display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-information-label {
        width: 100%;
  }

  &-self-checkin-container {
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        height: 260px;
        overflow-x: auto;
  }

  &-self-checkin-coach {
    width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &.check-in-member-option {
            width: 97%;
        }
  }

  &-self-checkin-time {
        display: flex;
        flex-direction: column;
        width: 30%;

        label {
            font-size: 12px;
            color: #212121;
            line-height: 18px;
            font-weight: bold;
        }
  }
}



.check-in-members-dialog-footer {
  margin-right: 40px;
  margin-top: 20px;
}

.check-in-member {
  display: flex;

  &__image-container {
    @include themify($themes) {
      border: themed('coach-pic-border');
    }
    height: 56px;
    width: 56px;
    border-radius: 4px;
    overflow: hidden;
  }

  &__photo {
    max-width: 100%;
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }

  &__text {
    font-size: 16px;
    letter-spacing: 0.13px;
    line-height: 24px;
  }
}

.check-in-member-menu {
  .iField-rs__menu-list {
    @extend %scrollbar-design;
    &::-webkit-scrollbar {
      width: 4px;
    }
  }
}

.check-in-member-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 8px;
  margin: 8px;
  border-radius: 4px;
  border: 1px solid rgba(151, 151, 151, 0.1);

  &:hover {
    background: $table-hover-color;
  }
}

.check-in-dialog {
  footer {
    display: none;
  }
}

.check-in-dialog-body {
  margin: 0 40px;
  &__header {
    box-sizing: border-box;
    height: 151px;
    border-left: 4px solid #95358F;
    border-radius: 4px;
    background-color: $table-alternate-color;
    margin: 24px auto 0;
    display: flex;
    align-items: center;
  }

  &__avatar {
    width: 118px;
    height: 118px;
    margin-left: 12px;

    .coach-avatar {
      min-width: 100%;
      min-height: 100%;
    }
  }

  &__header-details {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }

  &__header-name {
    font-size: 32px;
    font-weight: bold;
    line-height: 38px;
  }

  &__header-member-id {
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
  }

  &__header-username {
    font-size: 16px;
    line-height: 19px;
  }

  &__details {
    margin: 24px 0 40px;
  }

  .form-section-title {
    margin: 0;
  }
}

.can-pickup-value {
  height: 20px;

  &__image {
    max-height: 100%;
    margin-left: 25px;
  }
}

@media only screen and (min-width: 840px) {
  .coach-detail {
    .md-cell--3 {
      width: 25%;
    }

    .md-cell--12 {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 962px) {
  .form {
    &__section {
      width: 100%;
    }

    &__section-details {
      width: 100%;
    }

    &__block {
      flex-direction: column;

      > div {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &.form__school-field {
          margin-bottom: 0;
        }
      }
    }
  }

  .coach-detail {
    margin: 24px;
    padding: 24px;
  }

  .i_dialog {
    .i_dialog_body {
      .select-upload-choices {
        padding: 0 50px;

        .placeholder {
          width: 289px;
          height: 291px;
          background-size: 289px 291px;

          &:first-child {
            margin-right: 10px;
          }

          p {
            font-size: 18px;
          }
        }
      }
    }
  }

  .coach-overview {
    &__reward-points {
      flex-direction: column;
    }

    &__rewards {
      margin-bottom: 16px;
    }

    &__edit-points-button {
      margin: 0;
      padding: 0 0 0px 8px;
    }
  }
}

@media only screen and (min-width: 963px) and (max-width: 1024px) {
  .form {
    &__section {
      width: 100%;
    }

    &__section-details {
      width: 100%;
    }

    &__school-field {
      margin-bottom: 0;
    }
  }

  .coach-detail {
    margin: 32px;
    padding: 32px;
  }

  .coach-overview {
    &__edit-points-button {
      margin: 0;
      padding: 0 0 0px 8px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .coach-overview {
    padding: 8px 8px 29px;
    margin-top: 16px;

    &__upload-photo {
      width: 92px;
      height: 92px;
      margin-right: 16px;
    }

    &__name {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    &__member-id {
      font-size: 12px;

      .md-btn--icon {
        width: 28px;
        height: 28px;
        padding: 0;

        .md-icon {
          font-size: 16px;
        }
      }
    }

    &__username {
      font-size: 12px;
    }

    &__link {
      font-size: 12px;

      &--reset-password {
        margin-bottom: 0;
      }

      &--view-waiver {
        margin-bottom: 8px;
      }
    }

    &__rewards-icon {
      width: 20px;
    }

    &__number-of-points {
      font-size: 20px;
    }

    &__points {
      font-size: 16px;
    }
  }

  .coach-detail {
    margin: 16px;
    padding: 0;
    box-shadow: none;

    &__edit-information {
      top: 8px;
      right: 16px;
      font-size: 12px;
    }

    &__edit-information-img {
      width: 16px;
    }

    &__section {
      padding: 8px;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.1);
      border-radius: 4px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .md-grid {
      .md-cell {
        margin: 0 0 8px;
      }
    }

    .md-cell--4-phone.md-cell--4-phone {
      width: 100%;
    }

    .md-cell--3-phone.md-cell--3-phone {
      width: 75%;
    }

    .md-cell--2-phone.md-cell--2-phone {
      width: 50%;
    }

    .md-cell--1-phone.md-cell--1-phone {
      width: 25%;
    }
  }

  .coaches {
    &__form {
      margin: 16px;
      padding: 16px;
    }
  }

  .form {
    &__section {
      margin-bottom: 16px;
    }
  }

  .form-section-title {
    margin-top: 0;
    margin-bottom: 16px;

    &--pickup-contacts {
      margin-bottom: 2px;
    }
  }

  .tabs {
    &.view-edit-coaches {
      top: 236px;
    }
  }

  .coach-action-buttons {
    flex-direction: column-reverse;
    margin-top: 32px;
  }
}
