%dashboard-table {
  @extend %scrollbar-design;
  max-height: 253px;
  margin-bottom: 24px;

  .md-table-header {
    .md-table-row {
      th {
        background: $schedule-table-header-color;

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  .md-table-body {
    .md-table-row {
      border-left: unset;

      &--hover {
        td {
          &:first-child {
            border-left: unset;
          }
        }
      }

      &:nth-child(odd) {
        background: $white;

        &:hover {
          background: $table-hover-color;
        }
      }
    }
  }
}

%dashboard-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

%dashboard-header-button {
  width: 116px;
  height: 32px;
  font-size: 13px;
}

.dashboard {
  margin: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &__block {
    display: flex;
    justify-content: space-between;

    &--checked-in {
      width: calc(100% - 4px);
      flex-direction: column;
    }

    &--program-schedule {
      width: calc(100% - 4px);
      flex-direction: column;
      margin-top: 8px;
    }

    &--schedule {
      width: calc(70% - 4px);
      flex-direction: column;
    }

    &--attendance {
      width: calc(60% - 4px);
      flex-direction: column;
    }

    &--social {
      width: calc(30% - 4px);
    }

    &--leaderboard {
      margin-top: 8px;
    }

    &--points {
      margin-top: 48px;
    }
  }

  &__widget {
    display: inline-block;
    border-radius: 4px;
    background: $white;
    overflow: hidden;
    box-shadow: 0 7px 36px 0 rgba(48, 48, 48, 0.16);

    &--schedule {
      height: 348px;
      width: 100%;
    }

    &--social {
      width: 100%;
      height: 949px;
    }

    &--leaderboard {
      height: 592px;
      width: calc(40% - 4px);
    }

    &--attendance {
      // height: 269px;
      /*height: 100%;*/
      height: 46%;
      width: 100%;
    }

    &--todo {
      height: 54%;
      /*height: 316px;*/
      width: 100%;
      margin-top: 8px;
    }

    &--points {
      width: 100%;
      box-shadow: unset;
    }

    &--checked-in {
      height: 701px;
    }

    &--heads-up {
      height: 701px;
    }

    &--program-schedule {
      height: 506px;
    }
  }

  &__my-schedule {
    margin: 0;
    min-height: unset;
    height: 100%;
  }
}

.flex-direction {
  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-48 {
  margin-top: 48px;
}

// SCHEDULE
.schedule {
  height: 100%;
  padding: 24px 16px;

  &__header {
    margin-right: 24px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: bold;
  }

  &__button-view-schedule {
    height: 31px;
    font-size: 12.51px;
    letter-spacing: -0.08px;
    width: 116px;
  }

  &__table {
    @extend %dashboard-table;
  }
}

// LEADERBOARD
.leaderboard {
  padding: 16px 16px 0;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__button {
    height: 31px;
    width: 132px;
    font-size: 12px;
    letter-spacing: -0.08px;
  }

  &__title {
    height: 24px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }

  &__month {
    height: 24px;
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 24px;
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__leader {
    box-sizing: border-box;
    height: 89px;
    border: 1px solid $participant-green;
    border-radius: 4px;
    box-shadow: 0 2px 19px 0 rgba(122, 122, 122, 0.5);
    display: flex;
    padding: 16px;
    margin-bottom: 7px;
  }

  &__leader-img-container {
    position: relative;
    height: 56px;
    width: 56px;
    margin-right: 24px;
  }

  &__leader-img {
    max-height: 100%;
    border-radius: 4px;
  }

  &__leader-crown {
    position: absolute;
    top: -7px;
    left: -7px;
  }

  &__leader-name {
    font-size: 16px;
    letter-spacing: -0.1px;
    height: 24px;
    line-height: 24px;
    margin: 0;
  }

  &__leader-points-container {
    height: 32px;
    font-size: 24px;
    letter-spacing: -0.15px;
    line-height: 32px;
    margin: 0;
  }

  &__leader-points {
    font-weight: bold;
  }

  &__list-item {
    display: flex;
    height: 84px;
    border-bottom: 1px solid $light-gray-twelve;
    align-items: center;
    justify-content: space-between;
  }

  &__list-item-details-container {
    display: flex;
  }

  &__list-item-img-container {
    height: 44px;
    margin-right: 16px;
  }

  &__list-item-img {
    max-height: 100%;
    border-radius: 4px;
  }

  &__list-item-name {
    font-size: 16px;
    letter-spacing: -0.1px;
    height: 18px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  &__list-item-points-container {
    height: 21px;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 21px;
    margin-bottom: 0;
  }

  &__list-item-points {
    font-weight: bold;
  }

  &__list-item-last-updated {
    height: 14px;
    color: #979ea6;
    font-family: $primary-font, sans-serif;
    font-size: 10px;
    font-weight: 900;
    letter-spacing: -0.06px;
    line-height: 14px;
    // text-align: right;
  }
}

// TO DO COMPONENT
.todo {
  padding: 16px 12px 0 16px;

  &__title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }

  &__list-item {
    // height: 80px;
    border-bottom: 1px solid $light-gray-twelve;
    margin-top: 12px;
    padding: 10px 0;
  }

  &__list-item-title {
    font-size: 16px;
    letter-spacing: -0.1px;
    margin-bottom: 12px;
  }

  &__list-item-details {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 16px;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 4px;

    &--title {
      &-surveys {
        background: $participant-green;
      }

      &-messages {
        background: $magenta;
      }

      &-news {
        background: $blue-one;
      }
    }
  }

  &__icon {
    max-width: 100%;
  }

  &__text-container {
    display: flex;
    align-items: baseline;
  }

  &__size {
    font-weight: 500;
    font-size: 32px;
    margin-right: 8px;
  }

  &__description {
    font-size: 16px;
    // font-weight: 500;
  }
}

// SOCIAL FEED
.social {
  // background: ;

  &__header {
    height: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }

  &__title {
    font-size: 16px;
    font-weight: 900;
  }

  &__button-view-all {
    height: 31px;
    width: 116px;
    font-size: 12.51px;
    letter-spacing: -0.08px;
  }

  &__content {
    height: 906px;

    &.eapps-instagram-feed {
      overflow-y: auto !important;
      scrollbar-color: $scrollbar-color;

      &::-webkit-scrollbar {
        width: 5px;
        background-color: $scrollbar-color;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $participant-green;
        border-radius: 4px;
      }
    }
  }
}

// ATTENDANCE RECORD
.attendance-record {
  padding: 24px 24px 20px;

  &__title {
    display: block;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }

  &__legend-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 9px;
  }

  &__pie-chart {
    height: 164px;
  }

  &__sessions {
    text-align: right;
    margin-bottom: 0;
  }

  &__sessions-text {
    font-size: 12px;
    font-weight: 900;
  }

  &__sessions-total {
    font-weight: bold;
    font-size: 16px;
    margin-right: 5px;
  }

  &__legend-list-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__legend-list-item-color {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-right: 10px;

    &--present {
      background: linear-gradient(180deg, #0a6c00 0%, #50b948 100%);
    }

    &--absent {
      background-color: #b30000;
    }
  }

  &__legend-list-item-text {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 900;
    line-height: 19px;
    height: 19px;
  }
}

// POINTS CHART
.points-chart {
  &__points {
    display: flex;
    align-items: baseline;
  }

  &__overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__total-points {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: -0.08px;
    color: $dark-gray-four;
  }

  &__star-icon {
    margin-right: 12px;
  }

  &__bar-chart-container {
    padding: 16px 0 24px;
    margin-bottom: 21px;
    border-bottom: 1px solid $light-gray-twelve;
  }

  &__bar-chart {
    height: 16px;
    background-color: $light-gray-eleven;
    border-radius: 4px;
  }

  &__legend-list {
    display: flex;
    width: 468px;
    margin-bottom: 12px;
    justify-content: space-between;
  }

  &__points-number {
    font-weight: bold;
    font-size: 24px;
    margin-right: 6px;
  }

  &__points-text {
    font-size: 12px;
  }

  &__legend-list-item {
    display: flex;
    align-items: center;
  }

  &__legend-list-item-container {
    display: flex;
    flex-direction: column;
  }

  &__legend-list-item {
    font-size: 12px;
    letter-spacing: -0.07px;
    line-height: 18px;

    &--name {
      color: $dark-gray-four;
      text-transform: capitalize;
    }

    &--size {
      color: $black;
    }
  }

  &__legend-list-item-color {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: black;

    &:not(:last-child) {
      margin-right: 12px;
    }

    &--programs {
      background: $participant-green;
    }

    &--events {
      background: $black;
    }

    &--attendance {
      background: #00f000;
    }

    &--challenges {
      background: #006300;
    }

    &--surveys {
      background: #003000;
    }
  }
}

// HEADS UP REPORT
.heads-up-report {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px;
  }

  &__header-button {
    @extend %dashboard-header-button;
  }

  &__title {
    @extend %dashboard-title;
  }

  &__calendar {
    height: 320px;
    .react-datepicker {
      width: calc(100% - 48px);
      display: block;
      margin: 0 auto;
      border: none;

      &__header {
        background: none;
        border: none;
        padding: 0;

        .date-controls-wrapper {
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          margin-bottom: 21px;

          .date-controls {
            align-items: center;
            height: 36px;
            justify-content: space-between;
            width: 310px;
            margin: 0 auto 8px;

            .date-control {
              @include themify($themes) {
                background: themed('primary');
              }
              width: 15px;
              height: 15px;

              .date-control-image {
                height: 7px;
              }
            }

            .current-month {
              font-size: 16px;
              display: inline;
            }
          }
        }

        .react-datepicker__day-names {
          font-size: 10px;
          height: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .react-datepicker__day-name {
            width: 32px;
          }
        }
      }

      .react-datepicker__month-container {
        width: 100%;

        .react-datepicker__month {
          margin-top: 8px;

          .react-datepicker__week {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:not(:last-child) {
              margin-bottom: 18px;
            }

            .react-datepicker__day {
              font-size: 10px;
              height: 26px;
              width: 26px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              &--today {
                background: rgba(58, 122, 176, 0.12);
                border-radius: 50%;
                font-weight: normal;
                color: $blue-one;
                width: 26px;
                height: 26px;
                line-height: 26px;
              }

              &:hover {
                border-radius: 50%;
              }

              &--outside-month {
                visibility: hidden;
              }

              &--selected {
                @include themify($themes) {
                  background: themed('primary');
                }
                font-weight: bold;
                border-radius: 50%;
                color: $white;
                width: 26px;
                height: 26px;
                line-height: 26px;
              }
            }
          }
        }
      }

      .date-container {
        .date-bullets {
          position: absolute;
          display: flex;
          height: 6px;
          max-width: 37px;
          min-width: 17px;
          bottom: -8px;
          left: 50%;
          transform: translate(-50%, 0);

          .event-indicator {
            height: 4px;
            width: 4px;
            border-radius: 50%;
            margin: 0 auto;

            &:not(:last-child) {
              margin-right: 5px;
            }

            &.event {
              background: $black;
            }

            &.registered {
              background: $blue-one;
            }

            &.dropin {
              background: $magenta;
            }

            &.partner {
              background: $light-blue-two;
            }
          }
        }
      }
    }
  }

  &__reports {
    height: 300px;
    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.18);
    margin: 0 24px;
  }

  &__reports-all {
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
  }

  &__reports-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__reports-item {
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
    display: flex;
    align-items: baseline;
  }

  &__report-title {
    width: calc(100% - 26px);
    font-size: 16px;
    line-height: 24px;
    margin-left: 16px;
  }

  &__report-type {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &--event {
      background: $black;
    }

    &--registered {
      background: $blue-one;
    }

    &--dropin {
      background: $magenta;
    }

    &--partner {
      background: $light-blue-two;
    }
  }
}

// PROGRAM SCHEDULE
.program-schedule {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 70px 18px 24px;
  }

  &__header-button {
    @extend %dashboard-header-button;
  }

  &__title {
    @extend %dashboard-title;
  }

  &__events-list {
    height: 400px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 599px) {
  .dashboard {
    padding: 23px 16px;
    margin: 0;
    box-shadow: unset;

    &__block {
      &--schedule {
        width: auto;
      }

      &--leaderboard {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      &--attendance {
        width: 100%;
      }

      &--points {
        margin-top: 42px;
      }
    }

    &__widget {
      &--social {
        display: none;
      }

      &--attendance {
        display: none;
      }

      &--leaderboard {
        width: 100%;
      }

      &--todo {
        width: 100%;
      }
    }
  }

  .schedule {
    padding: 8px 12px;

    &__title {
      font-size: 16px;
      font-weight: normal;
    }

    &__header {
      margin-right: 0;
    }

    &__table {
      padding-right: 0;

      .md-table-column {
        &:first-child {
          width: 40%;
        }

        &:nth-child(2) {
          width: 22%;
        }
      }
    }
  }

  .leaderboard {
    padding: 8px 12px 0;

    &__title {
      font-weight: normal;
    }

    &__month {
      font-weight: bold;
      font-size: 12px;
    }
  }

  .points-chart {
    &__overview {
      margin-bottom: 0;
    }

    &__legend-list {
      display: none;
    }

    &__total-points {
      margin-bottom: 0;
    }
  }
}
