.store {
	&__add-and-search {
		&--participant {
			justify-content: space-between;
		}
	}

	&__cart-button-and-dialog {
		position: relative;
	}
}

.cart {
	&__content {
		@extend .page-table;
	}

	&__item {
		display: flex;
		padding: 28px 0 28px;
		border-bottom: 1px solid $light-gray-seven;
	}

	&__note {
		width: 80%;
	}

	&__summary {
		display: flex;
		border-bottom: 1px solid $light-gray-seven;
	}

	&__details {
	  width: 100%;
	}

	&__summary-table {
		padding: 5px 0 40px;
		font-size: 15px;
	}

	&__summary-table-column {
		line-height: 24px;
    font-weight: 600;
    width: 50%;
    display: flex;
	}

	&__summary-table-column:nth-child(2) {
		justify-content: flex-end;
		color: $blue-one;
	}

	&__summary-table-column-wrapper {
		justify-content: flex-start;
		color: #003074;
		display: flex;
		width: 115px;
	}

	&__out-of-stock {
	 // width: 60%;
		color: #ff0000c7;
    font-size: 15px;
	}

	&__summary-table-column-number {
		margin-right: 16px;
	}

	&__summary-table-column-points-value {
		font-weight: 500;
		font-size: 12px;
	}

	&__summary-table-column {
		line-height: 24px;
    font-weight: 600;
	}


	&__summary-table-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 16px 16px;
	}

	&__summary-table-row:nth-child(odd)  {
		background: #EDEDED;
	}

	&__summary-table-row:nth-child(even)  {
		background: #F9F9F9;
	}

	&__summary-label {
		font-size: 16px;
		font-weight: 600;
		padding-left: 16px;
	}

	&__image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 170px;
		height: 180px;
		margin-right: 48px;
	}

	&__image {
		max-width: 100%;
		max-height: 100%;
		cursor: pointer;
	}

	&__text {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 0;

		&--title {
			font-weight: bold;
			margin-bottom: 8px;
		}

		&--points {
			color: $blue-one;
			margin-bottom: 16px;
		}

		&--color {
			display: flex;
			align-items: center;
		}
	}

	&__out-of-stock {
		color: #ff0000c7;
		font-size: 15px;
	}

	&__points-label {
		margin-right: 10px;
		font-weight: bold;
	}

	&__color {
		height: 20px;
		width: 20px;
		margin-left: 8px;
		border-radius: 4px;
		border: 1px solid #e6e6e6;
	}

	&__remove {
		display: inline-block;
		color: $light-gray-sixteen;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		margin-top: 24px;
		text-decoration: underline;
		cursor: pointer;
	}

	&__totals {
		display: flex;
		justify-content: space-between;
		padding: 32px 0;
	}

	&__total-warning {
		color: #ff0000c9;
		font-weight: 500;
		text-align: right;
	}

	&__total-warning-text {
		color: #ff0000c9;
		font-weight: 500;
	}

	&__total-checkout {
		display: flex;
		justify-content: flex-end !important;
		text-align: right;
    flex-direction: column;
	}

	&__total-text {
		font-size: 24px;
		font-weight: bold;
	}

	&__total-points {
		color: $blue-one;
	}

	&__action-buttons {
		display: flex;
		justify-content: flex-end;
	}

	&__button {
		width: 252px;

		&--back {
			margin-right: 24px;
		}
	}
}

.confirmation {
	&__add-and-search {
		box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
	}

	&__header {
		height: 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		border-bottom: 1px solid $light-gray-seven;
	}

	&__header-label {
		font-size: 16px;
		font-weight: 500;
	}

	&__content {
		@extend .page-table;
	}

	&__content-body {
		padding: 0 63px;
		margin-bottom: 30px;
	}

	&__item {
		display: flex;
		padding: 28px 0 28px;
		border-bottom: 1px solid $light-gray-seven;
	}

	&__summary {
		display: flex;
	}

	&__details {
	  padding: 0 25px;
	  width: 100%;
	}

	&__summary-table {
		padding: 5px 0 40px;
		font-size: 15px;
	}

	&__summary-table-column {
		line-height: 24px;
    font-weight: 600;
    width: 50%;
    display: flex;
	}

	&__summary-table-column:nth-child(2) {
		justify-content: flex-end;
		color: $blue-one;
	}

	&__summary-table-column-wrapper {
		justify-content: flex-start;
		color: #003074;
		display: flex;
		width: 115px;
	}

	&__summary-table-column-number {
		margin-right: 16px;
	}

	&__summary-table-column-points-value {
		font-weight: 500;
		font-size: 12px;
	}

	&__summary-table-column {
		line-height: 24px;
    font-weight: 600;
	}


	&__summary-table-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 16px 16px;
	}

	&__summary-table-row:nth-child(odd)  {
		background: #EDEDED;
	}

	&__summary-table-row:nth-child(even)  {
		background: #F9F9F9;
	}

	&__summary-label {
		font-size: 16px;
		font-weight: 600;
		padding-left: 16px;
	}

	&__image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 170px;
		height: 180px;
		margin-right: 48px;
	}

	&__image {
		max-width: 100%;
		max-height: 100%;
		cursor: pointer;
	}

	&__text {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 0;

		&--title {
			font-weight: bold;
			margin-bottom: 8px;
		}

		&--points {
			color: $blue-one;
			margin-bottom: 16px;
		}

		&--color {
			display: flex;
			align-items: center;
		}
	}

	&__points-label {
		margin-right: 10px;
		font-weight: bold;
	}

	&__color {
		height: 20px;
		width: 20px;
		margin-left: 8px;
		border-radius: 4px;
		border: 1px solid #e6e6e6;
	}

	&__remove {
		display: inline-block;
		color: $light-gray-sixteen;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		margin-top: 24px;
		text-decoration: underline;
		cursor: pointer;
	}

	&__totals {
		display: flex;
		justify-content: space-between;
		padding: 32px 0;
	}

	&__total-checkout {
		display: flex;
		justify-content: flex-end !important;
		text-align: right;
    flex-direction: column;
	}

	&__total-text {
		font-size: 24px;
		font-weight: bold;
	}

	&__total-points {
		color: $blue-one;
	}

	&__action-buttons {
		display: flex;
		margin-top: 40px;
		justify-content: flex-start;
	}

	&__icon {
    height: 86px;
    margin-top: 38px;
    margin-bottom: 40px;
    display: flex;
	}

	&__icon-img {
	  max-height: 100%;
	}

	&__note {
		width: 80%;
	}

	&__button {
		width: 252px;

		&--back {
			margin-right: 24px;
		}
	}
}

.cart-pop-up {
	&__cart-dialog {
		position: absolute;
		top: 48px;
		right: 0;
		width: 300px;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
		border-radius: 4px;
		background: $white;
		z-index: 2;
		padding: 24px;
	}

	&__action-item {
		min-height: 337px;
		margin-top: 47px;
	}

	&__action-item-color {
		display: flex;
	}

	&__action-item-text {
		line-height: 24px;
	}

	&__action-item-name {
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
	}

	&__action-item-image {
		height: 166px;
		margin-bottom: 16px;
		text-align: center;
	}

	&__action-item-img {
		max-width: 100%;
		max-height: 100%;
		cursor: pointer;
	}

	&__action-item-points {
		font-size: 16px;
		color: $blue-one;
		padding: 16px 0;
		line-height: 24px;
	}

	&__action-item-label {
		font-weight: 600;
	}

	&__action-spacer {
		margin: 16px 0;
		border-bottom: 1px solid #DBDBDB;
	}

	&__action-total {
		display: flex;
    justify-content: space-between;
    margin-bottom: 56px;
	}

	&__action-total-count {
		font-weight: 600;
	}

	&__action-total-points {
		color: $blue-one;
	}
	&__color {
		height: 20px;
		width: 20px;
		margin-left: 8px;
		border-radius: 4px;
		border: 1px solid #e6e6e6;
	}

	&__action-total-label, {
		font-weight: 600;
	}

	&__success {
		color: $participant-green;
		font-weight: bold;
		font-size: 14px;
		margin-bottom: 0;
	}

	&__action-buttons {
		// margin-top: 56px;
	}

	&__button {
		&--proceed {
			margin-bottom: 16px;
		}
	}
}

.inventory {
	&__color {
		height: 20px;
		width: 20px;
		margin-left: 8px;
		border-radius: 4px;
		border: 1px solid #e6e6e6;
	}

	&__text--color {
    display: flex;
    align-items: center;
    justify-content: center;
	}

	&__header {
		height: 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		border-bottom: 1px solid $light-gray-seven;
	}

	&__header-label {
		color: #353535;
		font-size: 20px;
		font-weight: 500;
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		padding: 24px 16px 80px;
	}
}

.filter {
	position: relative;
	cursor: pointer;

	&__label {
		border: 1px solid $light-gray-seven;
		border-radius: 4px;
		width: 70px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__label-text {
		font-weight: bold;
	}

	&__icon {
		width: 16px;
		height: 8px;
	}

	&__dropdown {
		display: none;
		position: absolute;
		right: 0;
		width: 320px;
		background: $white;
		border: 1px solid $light-gray-seven;
		border-radius: 4px;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
		padding: 16px 8px;
		z-index: 1;

		&--open {
			display: block;
		}
	}

	&__dropdown-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 12px;
	}

	&__sort-by {
		font-weight: bold;
	}

	&__clear {
		height: 24px;
	}

	&__section {
	display: flex;
	height: 32px;
	align-items: center;
	padding: 0 8px;
	font-size: 14px;
	font-weight: bold;

		&--admin {
			background-color: rgba(149, 53, 143, 0.06);
		}

		&--participant {
			background-color: rgba(0, 134, 103, 0.06);
		}
	}

	&__radio-group {
		padding: 0;
		margin: 0;
	}

	&__radio {
		height: 30px;

		.md-btn--icon {
			height: 30px;
			width: 30px;
			padding: 5px;
		}
	}

	&__checkbox {
		height: 30px;

		.md-btn--icon {
			height: 30px;
			width: 30px;
			padding: 5px;
		}
	}

	&__apply-filter-button {
		height: 32px;
		margin-top: 24px;
	}
}

.product {
	width: 182px;
	height: 311px;
	display: flex;
	flex-direction: column;
	margin: 0 42px;

	&__image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 194px;
		margin-bottom: 8px;
		cursor: pointer;
	}

	&__image {
		max-width: 100%;
		max-height: 100%;
		cursor: pointer;
	}

	&__name {
		color: #353535;
		font-size: 16px;
		font-weight: 500;
		height: 48px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__points-label {
		color: $blue-one;
		font-weight: 500;
	}

	&__points-number {
		color: $blue-one;
	}
}

.add-product {
	&__header {
		height: 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		border-bottom: 1px solid $light-gray-seven;
	}

	&__header-label {
		font-size: 16px;
		font-weight: bold;
	}

	&__title {
		font-size: 20px;
		font-weight: bold;
		color: #353535;
		margin-bottom: 0;
	}

	&__description {
		margin: 8px 0 0;
	}

	&__body {
		padding: 16px 0;
	}

	&__product-color {
		position: relative;
		display: inline-block;
		cursor: pointer;
	}

	&__add {
		border-radius: 4px;
		border: 2px dashed $light-gray-eight;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__color-block {
		height: 72px;
	}

	&__color-picker {
		position: absolute;
		top: 20px;
		left: 20px;
		z-index: 2;
	}

	&__checkboxes {
		display: flex;

		.md-selection-control-container {
			margin-right: 16px;

			.md-btn--icon {
				height: 30px;
				width: 25px;
				padding: 5px 0;
			}
		}
	}

	&__radio-group {
		padding: 0;
		margin: 0;
	}

	&__radio {
		height: 30px;
		margin-right: 8px;

		&:last-child {
			margin-right: 0;
		}

		.md-btn--icon {
			height: 30px;
			width: 30px;
			padding: 5px;
		}

		.md-icon {
			font-size: 20px;
		}
	}

	&__radio-label {
		font-size: 12px;
		font-weight: bold;
	}

	&__action-buttons {
		display: flex;
		justify-content: flex-end;
		margin: 16px;

		&--product-details {
			margin-top: 0;
			margin-right: 0;
		}
	}

	&__size {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:not(:last-child) {
			margin-bottom: 8px;
		}
	}

	&__size-label {
		margin-right: 16px;
	}

	&__size-field {
		width: 90px;
	}

	&__label {
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 8px;
	}

	&__actions {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px;
		width: 100%;
	}

	&__button {
		width: 214px;

		&--back {
			margin-right: 24px;
		}
	}

	&__link {
		color: $magenta;
		cursor: pointer;
	}

	&__add-new-type {
		margin-top: 20px;
		height: 24px;
	}

	&__add-new-type-text-block {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    width: fit-content;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }
  }

  &__add-new-type-icon {
    width: 19px;
    margin-right: 8px;
  }

  &__add-new-type-text {
    color: $blue-one;
    font-weight: bold;
	}
}

.product-placeholder {
	display: inline-block;
	width: 209px;

	&:hover {
		cursor: pointer;
	}

	&__text {
		margin: 8px;
		text-align: center;
	}

	&__label {
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 8px;
	}

	&__product {
		height: 253px;
		border: 2px dashed $light-gray-eight;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 8px;
		position: relative;

		&-close {
			display: inline-block;
			width: 20px;
			height: 20px;
			position: absolute;
			right: 20px;
			top: 0;
		};
	}

	&__image {
		max-width: 100%;
		max-height: 100%;
	}
}

.upload-product, .view-product {
	padding: 0 40px;
	margin-top: 16px;
	display: flex;
	justify-content: space-between;
	color: #353535;

	&__image-preview-container {
		height: 423px;
		width: 358px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__image-preview {
		max-width: 100%;
		max-height: 100%;
	}

	&__image-list {
		margin-top: 40px;
		display: flex;
	}

	&__image-item-container {
		border: 1px solid $light-gray-eight;
		border-radius: 4px;
		width: 60px;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 8px;
		}
	}

	&__image {
		max-width: 100%;
		max-height: 100%;
		padding: 5px;
	}

	&__details {
		width: 322px;
	}

	&__title {
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		margin-bottom: 16px;
		color: #353535;
	}

	&__points {
		font-size: 16px;
		letter-spacing: 0;
		line-height: 21px;
		padding: 8px 0;
	}

	&__points-text {
		color: $blue-one;
		font-weight: bold;
		margin-right: 16px;
	}

	&__description {
		font-size: 14px;
		letter-spacing: 0;
		line-height: 24px;
	}

	&__info {
		display: inline-block;
		width: 50%;
		padding: 8px 0;

		&--block {
			display: block;
			width: 100%;
		}
	}

	&__label {
		display: block;
		font-weight: bold;
		font-size: 16px;

		&--color {
			font-size: 14px;
		}
	}

	&__value {
		display: block;
	}

	&__color-list {
		display: flex;
		align-items: center;
		margin-top: 8px;
	}

	&__color {
		width: 40px;
		height: 40px;
		border-radius: 4px;
		cursor: pointer;
		border: 1px solid #e6e6e6;

		&--active {
			border: 3px solid $magenta;
			width: 43px;
			height: 43px;
		}

		&--active-participant {
			border: 3px solid $participant-green;
			width: 43px;
			height: 43px;
		}

		&--disabled {
			opacity: 0.5;
	    cursor: no-drop;
		}

		&--active-image {
			border: 3px solid $magenta !important;
		}

		&--active-image-participant {
			border: 3px solid $participant-green !important;
		}

		&--participant {
			background-color: $participant-green;
		}

		&:not(:last-child) {
			margin-right: 8px;
		}
	}

	&__size-list {
		display: flex;
		padding: 8px;
	}

	&__size {
		width: 40px;

		&:not(:last-child) {
			margin-right: 8px;
		}
	}
}

.view-product {
	@extend .page-table;
	margin: 40px 32px;
	padding: 0;
	display: block;

	&__header {
		height: 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		border-bottom: 1px solid $light-gray-seven;
	}

	&__header-label {
		font-size: 16px;
		font-weight: bold;
	}

	&__body {
		@extend .md-grid;
		padding: 0 40px;
		margin-top: 16px;
		display: flex;
		justify-content: space-between;
	}

	&__images {
		@extend .md-cell;
		@extend .md-cell--6;
	}

	&__content {
		@extend .md-cell;
		@extend .md-cell--5;
		// @extend .md-cell--1-desktop-offset;
		margin-bottom: 100px;
	}

	&__sizes-available {
		margin-top: 24px;
	}

	&__size-options {
		display: flex;
		margin-top: 8px;
	}

	&__size-option {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		margin-right: 16px;
		border: 1px solid $black;
		cursor: pointer;

		&--unavailable {
			border-color: $light-gray-seven;
			cursor: no-drop;

			.view-product__size-option-value {
				color: $light-gray-seven;
			}
		}

		&--selected {
			border: 3px solid $participant-green;
			font-weight: bold;

			.view-product__size-option-value {
				color: $participant-green;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__actions {
		margin-top: 48px;
	}
}

.view-order {
	@extend .page-table;
	margin: 40px 32px;
	padding: 0;
	display: block;

	&__color {
		height: 20px;
		width: 20px;
		margin-left: 8px;
		border-radius: 4px;
		border: 1px solid #e6e6e6;
	}

	&__text--color {
    display: flex;
    align-items: center;
    justify-content: center;
	}

	&__header {
		height: 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 16px;
		border-bottom: 1px solid $light-gray-seven;
	}

	&__header-label {
		font-size: 16px;
		font-weight: bold;
	}

	&__body {
		padding: 24px 16px;
	}

	&__details {
		display: flex;
		justify-content: space-between;
	}

	&__heading {
		font-weight: bold;
		font-size: 18px;
	}

	&__text {
		font-size: 16px;
	}

	&__receipt-date {
		font-size: 18px;
	}

	&__strong {
		font-weight: bold;
	}

	&__billed-to {
		margin: 32px 0;
	}

	&__table {
		margin-bottom: 64px;

		.launchpad-table {
			padding-bottom: 0;

			thead {
				&.md-table-header {
					.md-table-row {
						th {
							border: 1px solid rgba(151,151,151,0.3);
							background: rgba(126,136,142,0.2);
							text-align: center;
						}
					}
				}
			}

			.md-table-body {
				.md-table-row {
					border: 1px solid rgba(151,151,151,0.3);

					.md-table-column {
						border: 1px solid rgba(151,151,151,0.3);
						text-align: center;

						&:hover {
							cursor: auto;
						}
					}
				}
			}
		}
	}

	&__action {
		display: flex;
		justify-content: space-between;

		&.end {
			justify-content: flex-end;
		}
	}

	&__button {
		width: 256px;
	}
}


@media only screen and (min-width: 320px) {
	.add-product {
		.md-selection-control-label {
			font-size: 14px;
		}
	}
}